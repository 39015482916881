import React from "react";
import {
  CentangIcon,
  ArrowRight,
  PendingIcon,
  CancelXIcon,
} from "assets/icons";

function TabShiftReplacement() {
  return (
    <div className=" h-[193px] flex flex-col gap-2">
      <h1>Requested on Sunday 18 july 2023</h1>
      <div className="flex w-full justify-between items-center">
        <p className="font-semibold">Morning Shift on Tuesday 22 july 2023</p>

        <div className="bg-[#A5F2CE]  h-[57px] rounded-xl flex justify-center items-center w-[183px] ">
          <h1 className="text-[#2dc47d] font-semibold">Pending Approval</h1>
        </div>
      </div>
      <p className="font-semibold">Cafe Halim - Cashier ( 15:00 - 22 : 00 )</p>

      <div className="flex gap-5  mt-5 items-center">
        <div className="flex gap-5">
          <div className="relative">
            <img
              src={
                "https://images.pexels.com/photos/140945/pexels-photo-140945.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              }
              alt="employee"
              className="h-[48px] w-[48px] rounded-full"
            />

            <div className="absolute top-[-2px] right-[-14px]">
              <CentangIcon />
            </div>
          </div>

          <div className="flex flex-col ">
            <p>Requestor</p>
            <h1 className="font-semibold">Helen</h1>
          </div>
        </div>
        <ArrowRight />

        <div className="flex gap-5">
          <div className="relative">
            <img
              src="https://images.pexels.com/photos/2505026/pexels-photo-2505026.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt="employee"
              className="h-[48px] w-[48px] rounded-full"
            />

            <div className="absolute top-[-2px] right-[-14px]">
              <PendingIcon />
            </div>
          </div>

          <div className="flex flex-col ">
            <p>Replacements</p>
            <h1 className="font-semibold">Halim</h1>
          </div>
        </div>

        <ArrowRight />

        <div className="flex gap-5">
          <div className="relative">
            <img
              src="https://images.pexels.com/photos/2505026/pexels-photo-2505026.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt="employee"
              className="h-[48px] w-[48px] rounded-full"
            />

            <div className="absolute top-[-2px] right-[-14px]">
              <CancelXIcon />
            </div>
          </div>

          <div className="flex flex-col ">
            <p>HR Admin</p>
            <h1 className="font-semibold">Joshua</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabShiftReplacement;
