import { createSlice } from '@reduxjs/toolkit'

export const employeeViewSlice = createSlice({
    name: 'employeeView',
    initialState: {
        dataDate: [],
        shiftDataTemplate: [],
        locationState: "",
        dateRange: {},
        dataEmployees: []
    },
    reducers: {
        setDataDate: (state, action) => {
            state.dataDate = action.payload;
        },
        setDataTemplate: (state, action) => {
            state.shiftDataTemplate.push(action.payload)
        },
        setLocationState: (state, action) => {
            state.locationState = action.payload
        },

        setDateRange: (state, action) => {
            state.dateRange = action.payload
        },
        setDataEmployee: (state, action) => {
            state.dataEmployees = action.payload
        }

    },

})

export const { setDataDate, setDataTemplate, setLocationState, setDateRange, setDataEmployee } = employeeViewSlice.actions;

export default employeeViewSlice.reducer;

