import React, { useState, useEffect } from "react";
import { startOfMonth, setDate } from "date-fns";
import { useFormik } from "formik";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import PublishDetailData from "./publishDetailData";


import AllButton from "./allButton";
import { setDataDate, setDateRange } from "store/employeeViewSlice";
import { generateDateRange } from "utils/common/generateDateRange";

function AllButtons() {
  // SHIFT DATE TEMPLATE
  const [confirmationPublish, setConfirmationPublish] = useState(false);

  const dispatch = useDispatch();

  // GET ALL DATA DATE

  const initialStartDate = startOfMonth(new Date());
  const initialEndDate = setDate(new Date(), 10);

  const resultDefault = generateDateRange(initialStartDate, initialEndDate);

  // SET VALUE DATE INCLUDES STARTDATE AND ENDDATE
  const { values, setFieldValue } = useFormik({
    initialValues: {
      date: resultDefault,
    },
  });

  useEffect(() => {
    setFieldValue("date", resultDefault);
  }, [dispatch]);

  dispatch(setDataDate(values.date));

  // VALUES DATE FOR EMPLOYEE MAP / ASSIGN SHIFT
  dispatch(
    setDateRange({
      fromDate: values.date[0],
      toDate: values.date[values.date.length - 1],
    })
  );

  return (
    <div>
      <div className="w-full">
        <AllButton
          setDateVal={setFieldValue}
          values={values}
          showConfirmPublish={() => {
            setConfirmationPublish(true);
          }}
        />
      </div>

      {/* Modal PUBLISH BASE ON LOCATION */}
      {confirmationPublish && (
        <ModalDialog
          title={"Publish Shift"}
          className={"w-[700px] h-[700px]"}
          onClose={() => setConfirmationPublish(false)}
        >
          <PublishDetailData
            values={values}
            setConfirmationPublish={setConfirmationPublish}
          />
        </ModalDialog>
      )}
    </div>
  );
}

export default AllButtons;
