import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";

import { usePostAssignShift } from "services/assignShiftService";
import FormAssignShift from "shared/formShift/formAssignShift";
import { UserAdd } from "assets/icons";
import { addAssignShiftSchema } from "./addAssignShiftSchema";
import { currentUser } from "utils/localstorage/user";
import { alertSuccess, alertError } from "services/alert";
import { formatDate } from "utils/common/generateDateRange";

function AddAssignShift({
  filterByShiftName,
  dataEmployee,
  dataAssign,
  selectJobs,
  setValueShiftName,
  shiftNameAssign,
  setShowModalAssign,
}) {
  const queryClient = useQueryClient();

  // POST DATA ASSIGN SHIFT
  const { mutate, isLoading } = usePostAssignShift({
    onSuccess: () => {
      queryClient.invalidateQueries("assignShift");
      queryClient.invalidateQueries("getDashboard");
      alertSuccess("Assign Shift Success");
    },
    onError: (err) => {
      alertError("Something Wrong!");
      console.log("something wrong ", err);
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      shiftName: "",
      color: "red",
      startTime: "",
      endTime: "",

      startBreakTime: "",
      endBreakTime: "",

      // REPEAT JUST ACCEPT VALUE ARRAY SEND TO API OBJECT > FROM - TO
      repeatDate: [],
      repeatBy: {
        repeatType: "",
        repeatValue: [],
      },
      locationId: "",
      jobPosition: "",
      employee: "",
    },
    onSubmit: (val) => {
      // const date = formatDate(filterByShiftName[0].date);

      const allData = {
        shiftId: val.shiftName,
        organizationId: currentUser.organizationID,
        userId: val.employee,
        jobPosition: val.jobPosition,
        date: filterByShiftName[0].date,
      };

      mutate(allData);
      setShowModalAssign(false);
    },
    validationSchema: addAssignShiftSchema,
  });

  const allJobPosition = dataAssign.shift[0].jobPosition;

  const filterEmployees =
    dataEmployee.length > 0 &&
    dataEmployee.flatMap((item) => {
      const allEmployee = item.employee.map((itemEmp) => {
        return {
          ...itemEmp,
          jobPosition: item.jobPosition,
        };
      });

      return allEmployee;
    });

  const filterSelectJobPosition =
    filterEmployees.length > 0 &&
    filterEmployees.filter((item) => item.jobPosition === allJobPosition);

  const selectEmployees = filterSelectJobPosition.map((item) => {
    return {
      label: `${item.firstName} ${item.lastName}`,
      value: item.uId,
    };
  });

  const employeeName = dataAssign.itemEmp.uId;

  // SET VALUE
  useEffect(() => {
    setFieldValue("employee", employeeName);

    if (filterByShiftName.length > 0) {
      const filterByName = filterByShiftName[0];

      setFieldValue("color", filterByName.color);
      setFieldValue("startTime", filterByName.startTime);
      setFieldValue("locationId", filterByName.locationId);
      setFieldValue("endTime", filterByName.endTime);
      setFieldValue("endBreakTime", filterByName.endBreakTime);
      setFieldValue("startBreakTime", filterByName.startBreakTime);
      setFieldValue("repeatDate", filterByName.repeatDate);
      setFieldValue("repeatBy.repeatType", filterByName.repeatBy.repeatType);
      setFieldValue("repeatBy.repeatValue", filterByName.repeatBy.repeatValue);
      setFieldValue("jobPosition", filterByName.jobPosition);
    }
  }, [filterByShiftName, setFieldValue]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <FormAssignShift
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        nameButton={"Assign Shift"}
        iconButton={<UserAdd />}
        setFieldValue={setFieldValue}
        selectEmployees={selectEmployees}
        selectJobs={selectJobs}
        setValueShiftName={setValueShiftName}
        shiftNameAssign={shiftNameAssign}
        touched={touched}
      />
    </div>
  );
}

export default AddAssignShift;
