import React from "react";

function UserAdd() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="8.50065"
        cy="5.33317"
        rx="2.66667"
        ry="2.66667"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67807 10.0057C9.29091 9.94654 8.89615 9.9165 8.49947 9.9165C7.20661 9.9165 5.93424 10.2356 4.87864 10.843C3.82348 11.4503 3.01635 12.3299 2.64158 13.3789C2.50222 13.769 2.70546 14.1981 3.09553 14.3375C3.4856 14.4769 3.91478 14.2736 4.05414 13.8836C4.28711 13.2315 4.81912 12.608 5.62683 12.1431C6.28259 11.7657 7.07219 11.522 7.91111 11.4438C8.13825 10.6574 8.83504 10.0702 9.67807 10.0057Z"
        fill="#FDFDFD"
      />
      <path
        d="M12.5 9.3335L12.5 14.6668"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.166 12L9.83268 12"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default UserAdd;
