import AppRouter from "routes";
import AlertService from "components/AlertService";

const App = () => {



  return (
    <>
      <AppRouter />

      <AlertService />
    </>
  );
}

export default App;
