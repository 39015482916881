import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputSelect } from "@bluesilodev/timhutcomponents";
import { Header } from "@bluesilodev/timhutcomponents";
import { LogoTimhut } from "assets/icons";
import { Outlet, useNavigate } from "react-router-dom";
import {
  loginKathLeen,
  loginLamineYamal,
  loginLeoGuntara,
  loginJakaTarung,
  loginJekaLim,
} from "utils/loginUser"; // Import fungsi login
import { tokenNow, userNow } from "utils/loginUser";
import { setLogoutReducer, setRoleReducer } from "store/userLoginSlice";
import { useGetAppsQuery } from "services/commonAPI";

function AdminLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSlice = useSelector((state) => state.loginSlice);
  const [currentUser, setCurrentUser] = useState(loginSlice.currentUser ? loginSlice.currentUser : null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
  const currentRole = loginSlice.currentRole;

  const [apps, setApps] = useState([]);
  
  const { data: resApp } = useGetAppsQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (resApp?.data) {
      setApps(resApp.data?.data);
    }
  }, [resApp]);

  useEffect(() => {
    setCurrentUser(loginSlice?.currentUser ? loginSlice?.currentUser : null);
  }, [loginSlice?.currentUser]);

  // State untuk menyimpan currentUser dan accessToken dari localStorage
  
  const [selectedEmp, setSelectedEmp] = useState(
    localStorage.getItem("selectedEmp") || "kathleen lalana"
  );

  // Fungsi untuk memanggil login berdasarkan employee yang dipilih
  const handleLogin = async (employee) => {
    switch (employee) {
      case "kathleen lalana":
        await loginKathLeen();
        break;
      case "lamine yamal":
        await loginLamineYamal();
        break;
      case "leo guntara":
        await loginLeoGuntara();
        break;
      case "jaka tarung":
        await loginJakaTarung();
        break;
      case "jeka lim":
        await loginJekaLim();
        break;
      default:
        await loginKathLeen(); // Default ke Kathleen jika tidak ada yang dipilih
    }
    // Setelah login berhasil, ambil kembali data dari localStorage
    setCurrentUser(JSON.parse(localStorage.getItem("currentUser")));
    setAccessToken(localStorage.getItem("accessToken"));
  };

  // Efek untuk memanggil login berdasarkan selectedEmp saat komponen di-mount atau employee diubah
  // useEffect(() => {
  //   const savedEmp = localStorage.getItem("selectedEmp");
  //   if (savedEmp) {
  //     handleLogin(savedEmp); // Panggil login berdasarkan employee yang disimpan
  //   } else {
  //     localStorage.setItem("currentUser", JSON.stringify(userNow));
  //     localStorage.setItem("accessToken", tokenNow);
  //   }
  // }, []);

  // Ketika employee dipilih, update selectedEmp di state dan localStorage, kemudian refresh halaman
  const handleEmployeeChange = (e) => {
    const selected = e.target.value;
    setSelectedEmp(selected);
    localStorage.setItem("selectedEmp", selected); // Simpan employee yang dipilih di localStorage

    // Setelah employee dipilih, jalankan login yang sesuai
    handleLogin(selected).then(() => {
      window.location.reload(); // Refresh halaman setelah login berhasil
    });
  };

  // console.log("CURRENT : ", userNow);
  // console.log("ACCES OTKE N: ", tokenNow);

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");

    // using window as we used microservice frontend and need to redirect to workbench
    window.location.assign("/dashboard");
  }

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  }

  const onClickApp = (link) => {
    window.location.assign(link);
  }

  const onClickMyAccount = (link) => {
    window.location.assign(`${link}/myAccount`);
  }

  /* DELETE SOON
  const changeRole = () => {
    const roleValue = role === "Employee" ? "Admin" : "Employee";
    localStorage.setItem("role", roleValue);
    navigate(0);
  };
  */
  const changeRole = (currentRole) => {
    localStorage.setItem("role", currentRole);
    navigate(0);
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-5">
      <div className="w-full">
        <div className="w-full mb-5">
          {
            apps.length > 0 && (
              <Header
                title={<LogoTimhut />}
                apps={apps}
                switchDisabled={currentUser?.role?.length < 2}
                userData={{
                  name: currentUser?.userName,
                  role: currentRole,
                  language: "English",
                  switchRole: currentRole === "Admin" ? "User" : "Admin",
                  image: currentUser?.photo?.length > 0 ? currentUser?.photo[0].link : "",
                }}
                onSwitch={onClickSwitch}
                onClickMyAccount={onClickMyAccount}
                onClickLogout={onClickLogout}
                onClickApp={onClickApp}
              />
            )
          }
        </div>

        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
