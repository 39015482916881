import { useQuery } from '@tanstack/react-query'
import customAxios from 'utils/axios';

// const API_LOCATION = process.env.REACT_APP_API_LOCATION
const API_LOCATION = `${process.env.REACT_APP_API_SHIFT}/api/employee/location`

const API_EMPLOYEE = `${process.env.REACT_APP_API_SHIFT}/api/employee`

export const useGetAllEmployee = (props = {}) => {
    let queryParams = "";

    const { firstName, location, jobPosition } = props;

    const getEmployee = async () => {
        // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
        if (firstName) queryParams += `firstName=${firstName}&`;
        if (location) queryParams += `location=${location}&`;
        if (jobPosition) queryParams += `jobPosition=${jobPosition}&`;

        // Menghapus tanda '&' di akhir URL jika diperlukan
        if (queryParams.endsWith('&')) {
            queryParams = queryParams.slice(0, -1);
        }

        const url = queryParams ? `${API_EMPLOYEE}?${queryParams}` : API_EMPLOYEE;

        const resp = await customAxios.get(`${url}?limit=999`)

        return resp.data;
    }

    const queryKey = ["getEmployee"];
    if (firstName) queryKey.push(`firstName=${firstName}`);
    if (location) queryKey.push(`location=${location}`);
    if (jobPosition) queryKey.push(`jobPoosition=${jobPosition}`);

    return useQuery({
        queryKey: queryKey.length >= 1 ? queryKey : ["getEmployee"],
        queryFn: getEmployee,
        onError: (err) => {
            console.log("Something Wrong ", err)
        }
    })
}

export const useGetAllLocation = () => {
    const getLocation = async () => {
        const resp = await customAxios.get(`${API_LOCATION}`)
        return resp.data;
    }

    return useQuery({
        queryFn: getLocation,
        onError: (err) => {
            console.log("Get Location Error ", err)
        }
    })
}