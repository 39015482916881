import React from "react";
import { TimeCircle } from "assets/icons";

function TabExceedingTime() {
  return (
    <div className="">
      <h1 className="font-semibold">
        Exceeding Time 78 Hours per Week on 19-25 July 2023
      </h1>
      <p className="font-semibold">Cafe Halim {">"} Supervisor</p>

      <div className="flex items-center gap-3 mt-3">
        <img
          src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="img1"
          className="w-[40px] h-[40px] rounded-full"
        />
        <div>
          <h1>Halim</h1>
          <div className="flex gap-1 items-center">
            {" "}
            <TimeCircle className="text-red-500" />{" "}
            <p className="text-red-500">78 Hours / Week</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabExceedingTime;
