import React, { useContext, createContext, useState } from "react";

const ConflickReplacementContext = createContext();

export const useReplacementConflick = () => {
  return useContext(ConflickReplacementContext);
};

function ReplacementConflickProvider({ children }) {
  // setvalue conflick data
  const [dataConflick, setDataConflick] = useState([]);
  //   set show modal conflick
  const [modalConflickData, setModalConflickData] = useState(false);

  // CONFLICK REPLACEMEMENT
  //  CALL BACK GET DATA CONFCLIK FROM SHIFT LIST BOX
  const handleConflickData = (data) => {
    console.log("DATA CONFLICK : ", data);

    setDataConflick(data);
    setModalConflickData(true);
  };

  const onCloseModalConflick = () => {
    setModalConflickData(false);
  };

  // END CONFLICK REPLACEMENT

  const contextVal = {
    handleConflickData,
    modalConflickData,
    onCloseModalConflick,
    dataConflick,
  };

  return (
    <ConflickReplacementContext.Provider value={contextVal}>
      {children}
    </ConflickReplacementContext.Provider>
  );
}

export default ReplacementConflickProvider;
