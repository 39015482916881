import React from "react";

function ErrorSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1_14125"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_14125)">
        <path
          d="M5.61675 15.3337C5.39946 15.3337 5.19223 15.2929 4.99504 15.2114C4.7984 15.13 4.62539 15.0145 4.47601 14.8651L1.13527 11.5244C0.985884 11.375 0.870452 11.202 0.78897 11.0054C0.707489 10.8082 0.666748 10.6009 0.666748 10.3837V5.61699C0.666748 5.39971 0.707489 5.19247 0.78897 4.99529C0.870452 4.79865 0.985884 4.62563 1.13527 4.47625L4.47601 1.13551C4.62539 0.986128 4.7984 0.870696 4.99504 0.789214C5.19223 0.707733 5.39946 0.666992 5.61675 0.666992H10.3834C10.6007 0.666992 10.8079 0.707733 11.0051 0.789214C11.2018 0.870696 11.3748 0.986128 11.5242 1.13551L14.8649 4.47625C15.0143 4.62563 15.1297 4.79865 15.2112 4.99529C15.2927 5.19247 15.3334 5.39971 15.3334 5.61699V10.3837C15.3334 10.6009 15.2927 10.8082 15.2112 11.0054C15.1297 11.202 15.0143 11.375 14.8649 11.5244L11.5242 14.8651C11.3748 15.0145 11.2018 15.13 11.0051 15.2114C10.8079 15.2929 10.6007 15.3337 10.3834 15.3337H5.61675ZM8.00008 12.0744C8.23095 12.0744 8.4246 11.9962 8.58104 11.8397C8.73695 11.6838 8.8149 11.4904 8.8149 11.2596C8.8149 11.0287 8.73695 10.8351 8.58104 10.6786C8.4246 10.5227 8.23095 10.4448 8.00008 10.4448C7.76922 10.4448 7.57583 10.5227 7.41993 10.6786C7.26349 10.8351 7.18527 11.0287 7.18527 11.2596C7.18527 11.4904 7.26349 11.6838 7.41993 11.8397C7.57583 11.9962 7.76922 12.0744 8.00008 12.0744ZM8.00008 8.81514C8.23095 8.81514 8.4246 8.73692 8.58104 8.58047C8.73695 8.42457 8.8149 8.23119 8.8149 8.00033V4.74107C8.8149 4.5102 8.73695 4.31655 8.58104 4.1601C8.4246 4.0042 8.23095 3.92625 8.00008 3.92625C7.76922 3.92625 7.57583 4.0042 7.41993 4.1601C7.26349 4.31655 7.18527 4.5102 7.18527 4.74107V8.00033C7.18527 8.23119 7.26349 8.42457 7.41993 8.58047C7.57583 8.73692 7.76922 8.81514 8.00008 8.81514ZM5.63712 13.704H10.363L13.7038 10.3633V5.63736L10.363 2.29662H5.63712L2.29638 5.63736V10.3633L5.63712 13.704Z"
          fill="#1A1A2E"
        />
      </g>
    </svg>
  );
}

export default ErrorSvg;
