import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setShowRequesReplacement } from "store/requestReplacementSlice";

import { useUpdateRequesReplacement } from "services/replacementService";
import {
  useUpdateAssignShift,
  useGetByIdAssign,
} from "services/assignShiftService";
import { generateDateFromTo } from "utils/common/generateDateRange";

import FormAssignShift from "shared/formShift/formAssignShift";
import { UserAdd } from "assets/icons";
import { editAssignShiftSchema } from "./editAssignShiftSchema";
// import ApprovalStatus from "./approvalStatus";
import ApproveData from "components/approveData";
import RequestApproveModal from "components/requestApproveModal";
import { currentUser } from "utils/localstorage/user";
import { alertSuccess, alertError } from "services/alert";

function EditAssignShift({
  shiftData,
  uIdData,
  setShowModalEditAssign,
  dataEmployee,
  setTitleReplacement,
}) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [valueShiftName, setValueShiftName] = useState();
  const [showApproveModal, setApproveModal] = useState(false);
  // const [replacementStatus, setReplacementStatus] = useState();

  // POST DATA ASSIGN SHIFT
  const { mutate: updateAssignShift, isLoading } = useUpdateAssignShift({
    uId: uIdData,
    onSuccess: () => {
      queryClient.invalidateQueries("getAssignShift");
      alertSuccess("Edit Assign Shift Success");
      setShowModalEditAssign(false);
    },
    onError: (err) => {
      alertError("Something Wrong!");
      console.log("something wrong ", err);
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      shiftName: "",
      color: "red",
      startTime: "",
      endTime: "",

      startBreakTime: "",
      endBreakTime: "",

      // REPEAT JUST ACCEPT VALUE ARRAY SEND TO API OBJECT > FROM - TO
      repeatDate: [],
      repeatBy: {
        repeatType: "",
        repeatValue: [],
      },
      locationId: "",
      jobPosition: "",
      employee: "",
    },
    onSubmit: (val) => {
      // shiftId, organizationId, userId, date, jobPosition

      const allData = {
        shiftId: val.shiftName,
        userId: val.employee,
        jobPosition: val.jobPosition,
      };

      updateAssignShift(allData);
    },
    validationSchema: editAssignShiftSchema,
  });

  // FILTERING BY SHIFTNAME
  const filterByShiftName =
    shiftData &&
    shiftData.allShift.length > 0 &&
    shiftData.allShift.filter((item) => item.uId === valueShiftName);

  useEffect(() => {
    // const isReplacementStatus = shiftData.assignShift.isReplacement;
    // setReplacementStatus(isReplacementStatus);
    if (filterByShiftName.length > 0) {
      // Ensure that the data you're trying to set is available
      const shiftDetails = filterByShiftName[0];

      if (shiftDetails) {
        setFieldValue("color", shiftDetails.color);
        setFieldValue("startTime", shiftDetails.startTime);
        setFieldValue("shiftName", shiftDetails.uId);
        setFieldValue("endTime", shiftDetails.endTime);
        setFieldValue("endBreakTime", shiftDetails.endBreakTime);
        setFieldValue("startBreakTime", shiftDetails.startBreakTime);
        setFieldValue(
          "repeatDate",
          generateDateFromTo(shiftDetails.repeatDate)
        );
        setFieldValue("jobPosition", shiftData.assignShift.jobPosition);

        setFieldValue("repeatBy.repeatType", shiftDetails.repeatBy.repeatType);
        setFieldValue(
          "repeatBy.repeatValue",
          shiftDetails.repeatBy.repeatValue
        );
      }
    } else {
      // Fall back to default shift data if no specific shift name is selected
      const detailAssignShift = shiftData?.assignShift?.shiftDetails;

      if (detailAssignShift) {
        const repeat = generateDateFromTo(detailAssignShift.repeatDate);

        setFieldValue("shiftName", detailAssignShift.uId);
        setFieldValue("startTime", detailAssignShift.startTime);
        setFieldValue("color", detailAssignShift.color);
        setFieldValue("endTime", detailAssignShift.endTime);
        setFieldValue("endBreakTime", detailAssignShift.endBreakTime);
        setFieldValue("startBreakTime", detailAssignShift.startBreakTime);
        setFieldValue("repeatDate", repeat);
        setFieldValue("jobPosition", shiftData.assignShift.jobPosition);

        // if (shiftDetails.employees && shiftDetails.employees.length > 0) {
        //   setFieldValue("jobPosition", shiftData.assignShift[0].jobPosition);
        // }

        setFieldValue("locationId", detailAssignShift.locationId);
        setFieldValue("employee", shiftData.assignShift.userId);
        setFieldValue(
          "repeatBy.repeatType",
          detailAssignShift.repeatBy.repeatType
        );
        setFieldValue(
          "repeatBy.repeatValue",
          detailAssignShift.repeatBy.repeatValue
        );
      }
    }
  }, [shiftData, valueShiftName, setFieldValue]);

  // GET BY ID ASSIGN SHIFT
  const { data: dataAssignShiftById, isLoading: loadingByIdAssign } =
    useGetByIdAssign({ uId: uIdData });

  // UPDATE REQUEST REPLACEMT
  const { mutate: updateRequestReplacement, isLoading: loadingUpdateRequest } =
    useUpdateRequesReplacement({
      onSuccess: () => {
        alert("Data Approved");
        queryClient.invalidateQueries("getRequestReplacement");
        queryClient.invalidateQueries("getAssignShift");
        setApproveModal(false);
      },
      onError: (err) => {
        alert("Something Wrong!");
        console.log("Something Wrong : ", err);
      },
    });

  if (isLoading || loadingUpdateRequest || loadingByIdAssign) {
    return <h1>Loading...</h1>;
  }

  // SELECT SHIFT NAME
  const selectShiftName =
    shiftData &&
    shiftData.allShift.length > 0 &&
    shiftData.allShift.map((item) => {
      return {
        label: item.shiftName,
        value: item.uId,
      };
    });

  // SELECT JOB POSITION
  const selectJobposition =
    shiftData &&
    shiftData.allShift.length > 0 &&
    shiftData.allShift.flatMap((item) => {
      return (
        item.employees.length > 0 &&
        item.employees.map((itemEmp) => {
          return {
            label: itemEmp.jobPosition,
            value: itemEmp.jobPosition,
          };
        })
      );
    });

  const allJobPosition = shiftData && shiftData.assignShift.jobPosition;

  const filterEmployees =
    dataEmployee.length > 0 &&
    dataEmployee.flatMap((item) => {
      const allEmployee = item.employee.map((itemEmp) => {
        return {
          ...itemEmp,
          jobPosition: item.jobPosition,
        };
      });

      return allEmployee;
    });

  const filterSelectJobPosition =
    filterEmployees.length > 0 &&
    filterEmployees.filter((item) => item.jobPosition === allJobPosition);

  const selectEmployees = filterSelectJobPosition.map((item) => {
    return {
      label: `${item.firstName} ${item.lastName} `,
      value: item.uId,
    };
  });

  setTitleReplacement(shiftData.assignShift.isActive);

  // handle find request replacement
  const handleReplacementRequest = () => {
    console.log("REQUEST REPLACEME CLICK");
    // setShowRequestReplacement(true);
    dispatch(setShowRequesReplacement(true));
  };

  const isActive = shiftData.assignShift.isActive;

  const handleApproveRequest = () => {
    setApproveModal(true);
  };

  // replacementRequestId, status, rejectMessage

  const dataAssignReplaceById =
    dataAssignShiftById && dataAssignShiftById.replacementRequestData;

  const statusbyAdmin =
    dataAssignReplaceById &&
    dataAssignShiftById.replacementRequestData.status.byAdmin.status;

  const statusEmployee =
    dataAssignReplaceById &&
    dataAssignShiftById.replacementRequestData.status.byEmployee.status;

  const employeeUid = shiftData && shiftData.employee.uId;

  const shiftDetails = dataAssignShiftById && dataAssignShiftById.shift;
  const employeeRequest = dataAssignShiftById.employeeData;
  const assignShift = dataAssignShiftById.assignShift;
  const createdByAdmin = dataAssignShiftById.createdBy;

  const dataReplacementEmployee =
    dataAssignReplaceById && dataAssignReplaceById.replacementEmployeeData;

  const isShowButtonRequest =
    isActive === true &&
    (dataAssignReplaceById === null ||
      statusbyAdmin === "approved" ||
      statusEmployee === "rejected") &&
    (currentUser.role.includes("Admin") || employeeUid === currentUser.uId);

  const handleAccept = () => {
    const bodyUpdate = {
      replacementRequestId: dataAssignShiftById?.replacementRequestData?.uId,
      status: "approved",
      rejectMessage: "",
    };

    updateRequestReplacement(bodyUpdate);
  };

  const handleReject = () => {
    const bodyUpdate = {
      replacementRequestId: dataAssignShiftById?.replacementRequestData?.uId,
      status: "rejected",
      rejectMessage: "message of reject",
    };

    updateRequestReplacement(bodyUpdate);
  };


  console.log("DATA REPLACEMENT: ", dataReplacementEmployee)

  return (
    <div>
      <FormAssignShift
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        nameButton={"Update Assign Shift"}
        iconButton={<UserAdd />}
        setFieldValue={setFieldValue}
        selectEmployees={selectEmployees}
        selectJobs={selectJobposition}
        setValueShiftName={setValueShiftName}
        valueShiftName={valueShiftName}
        shiftNameAssign={selectShiftName}
        touched={touched}
        isActiveStatus={shiftData.assignShift.isActive}
        isReplacementStatus={isShowButtonRequest}
        handleFindReplacement={handleReplacementRequest}
        // statusReplacement={replacementAllData}
      />
      {/* dateRequest, shiftName, startTime, endTime, employeeName, nameReplacement,
  hrAdminName, assignShiftDate, statusApprove, locationName, jobPosition,
  requestorPhoto, replacementPhoto, hrAdminPhoto, handleApproveRequest, */}
      {/* APPROVAL REPLACEMENT STATUS */}

      {dataAssignReplaceById &&
        statusEmployee !== "rejected" &&
        statusbyAdmin !== "approved" && (
          <div>
            <ApproveData
              dateRequest={dataAssignReplaceById.createdAt}
              assignShiftDate={shiftData.assignShift.date}
              shiftName={shiftDetails.shiftName}
              locationName={employeeRequest.locationName}
              startTime={shiftDetails.startTime}
              endTime={shiftDetails.endTime}
              employeeName={employeeRequest.firstName}
              requestorPhoto={employeeRequest.photo}
              hrAdminName={createdByAdmin.firstName}
              hrAdminPhoto={createdByAdmin.photo}
              jobPosition={assignShift.jobPosition}
              statusAproveHr={statusbyAdmin}
              nameReplacement={dataReplacementEmployee.firstName}
              replacementPhoto={dataReplacementEmployee.photo}
              statusAproveReplacement={statusEmployee}
              handleApproveRequest={handleApproveRequest}
              replacementUserData={dataReplacementEmployee}
              // adminId={hrAdminDetail.uId}
              statusClick={true}
            />
          </div>
        )}
      {showApproveModal && (
        <RequestApproveModal
          setApproveModal={setApproveModal}
          dateRequest={dataAssignReplaceById.createdAt}
          assignShiftDate={shiftData.assignShift.date}
          shiftName={shiftDetails.shiftName}
          locationName={employeeRequest.locationName}
          startTime={shiftDetails.startTime}
          endTime={shiftDetails.endTime}
          employeeName={employeeRequest.firstName}
          requestorPhoto={employeeRequest.photo}
          nameReplacement={dataReplacementEmployee.firstName}
          replacementPhoto={dataReplacementEmployee.photo}
          statusAproveReplacement={statusEmployee}
          statusAproveHr={statusbyAdmin}
          onReject={handleReject}
          onAccept={handleAccept}
          hrAdminName={createdByAdmin.firstName}
          hrAdminPhoto={createdByAdmin.photo}
          jobPosition={assignShift.jobPosition}
          adminId={createdByAdmin.uId}
          replacementUserData={dataReplacementEmployee}
        />
      )}
    </div>
  );
}

export default EditAssignShift;
