import React from "react";
import ReplacementConflickProvider from "context/replacementConflickProvider";
import { Outlet } from "react-router-dom";
import AllButtons from "./allButton";

function Dashboard() {
  return (
    <ReplacementConflickProvider>
      <AllButtons />

      <div>
        <Outlet />
      </div>
    </ReplacementConflickProvider>
  );
}

export default Dashboard;
