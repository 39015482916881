import React, { useState } from "react";
import { InputSelect, SearchBox, Button } from "@bluesilodev/timhutcomponents";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { useRequestReplacement } from "services/replacementService";
import { setShowRequesReplacement } from "store/requestReplacementSlice";

import { FiCheckCircle } from "assets/icons";
import { useGetAllEmployee } from "services/employeeApi";
import { UserAdd } from "assets/icons";
import { alertSuccess, alertError } from "services/alert";

function FindReplacementRequest({ shiftData }) {
  const [checkEmployee, setCheckEmployee] = useState("");
  const [employeeOne, setEmployeeOne] = useState([]);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [selectJobPosition, setSelectJobposition] = useState("");
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: employeeData, isLoading: loadingEmployee } = useGetAllEmployee({
    jobPosition: selectJobPosition,
  });

  const { mutate: requestReplacement, isLoading: loadingRequesReplacement } =
    useRequestReplacement({
      onSuccess: () => {
        alertSuccess("Request Replacement Success");
        queryClient.invalidateQueries("getRequestReplacement");
        dispatch(setShowRequesReplacement(false));
      },
      onError: (err) => {
        alertError("Request Failed, Something Wrong!");
        console.log("Something Wrong !", err);
      },
    });

  if (loadingEmployee || loadingRequesReplacement) {
    return <div>Loading...</div>;
  }

  const employeeUid = shiftData?.employee?.uId;

  const selectJobposition =
    employeeData.data.length > 0 ?
    employeeData.data.map((item) => {
      return {
        label: item.jobPosition,
        value: item.jobPosition,
      };
    }) : [];

  // console.log("SHIFT DATA : ", shiftData);

  // FILTER BASE ON JOB POSITION
  // const filterEmployeeJob =
  //   employeeData.data.length > 0 &&
  //   employeeData.data.filter((item) => item.jobPosition === baseOnjobAssign);

  const allEmployee =
    employeeData.data.length > 0 &&
    employeeData.data.flatMap((item) => {
      // FILTER EMPLOYEE NON ADMIN
      const nonAdmin = item.employee.filter((filter) => {
        return filter.role !== "Admin" && filter.uId !== employeeUid;
      });

      return nonAdmin.map((emp) => ({
        ...emp,
        jobPosition: item.jobPosition,
      }));
    });

  const handleCheckEmployee = (item) => {
    console.log(item);
    setEmployeeOne(item);
    setCheckEmployee(item.uId);
  };

  const requestPostReplacement = () => {
    // console.log("REQUEST POST REPLACEMENT", shiftData);

    const dataReplacement = {
      assignShiftId: shiftData?.assignShift?.uId,
      previousUserId: shiftData?.employee?.uId,
      replacementUserId: employeeOne?.uId,
    };

    requestReplacement(dataReplacement);
  };

  // FILTER WITHOUT IT SELF

  // const filterEmployeeItSelf =
  //   allEmployee.length > 0 &&
  //   allEmployee.filter((item) => {
  //     return item.uId !== shiftData.employee.uId;
  //   });

  return (
    <div className="">
      <p>Select a employees to replace you in this shift</p>
      <div className="flex justify-between gap-5 mt-2 ">
        <div className="w-[50%]">
          <InputSelect
            title={"Job Positions"}
            options={selectJobposition}
            value={selectJobPosition}
            onChange={(e) => setSelectJobposition(e.target.value)}
          />
        </div>
        <h1>{searchEmployee}</h1>

        <div className="w-[90%] ">
          <SearchBox
            value={searchEmployee}
            onChange={(e) => {
              setSearchEmployee(e.target.value);
            }}
            className={"h-[60px]"}
            placeholder={"Search Employee"}
          />
        </div>

        <Button label={<div>Search</div>} />
      </div>

      <div className="flex flex-col gap-3 mt-3">
        {allEmployee.length > 0 &&
          allEmployee.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleCheckEmployee(item)}
                className={`${
                  checkEmployee === item.uId
                    ? "border-[1px] border-[#DD7224]"
                    : "border-[1px] border-gray-300"
                } w-full h-[72px] rounded-md hover:bg-[#FFD9BE] duration-300 transition-all hover:border-[#DD7224] cursor-pointer p-2 flex items-center justify-between select-none`}
              >
                <div className="flex gap-3 items-center">
                  <img
                    className="w-[40px] h-[40px] rounded-full"
                    src={`${item.photo}`}
                    alt="igm1"
                  />
                  <div>
                    <h1>{item.firstName}</h1>
                    <p>{item.jobPosition}</p>
                  </div>
                </div>
                {checkEmployee === item.uId && <FiCheckCircle />}
              </div>
            );
          })}
      </div>

      <Button
        onClick={requestPostReplacement}
        label={
          <div className="flex gap-3 items-center">
            <UserAdd />
            <h1>Select Employee</h1>
          </div>
        }
        style={"solid"}
        className={"mt-5 mb-3 w-full"}
      />
    </div>
  );
}

export default FindReplacementRequest;
