import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import LayoutShiftScheduling from 'layouts/adminLayout';
import { Dashboard, EmployeeView, RoleView } from 'pages/dashboard/indexShare';

// import HomeCoba from 'coba/divRefMap/HomeCoba';
import { useDispatch } from "react-redux";
import { setLoginReducer } from "store/userLoginSlice";


const AppRouter = () => {

  // CEK FROM API USER ROLE

  const dispatch = useDispatch();

  useEffect(() => {
    // first time set reducer if got localstorage
    const localStorageCurrentUser = JSON.parse(localStorage.getItem("currentUser"));
    const localStorageAccessToken = localStorage.getItem("accessToken");

    if (localStorageCurrentUser && localStorageAccessToken) {
      dispatch(setLoginReducer({
        currentUser: localStorageCurrentUser,
        token: localStorageAccessToken,
      }));
    } else {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/"
      }
    }
  }, []);

  return (
    <Router basename={window.__POWERED_BY_QIANKUN__ ? '/scheduling' : '/'} >
      <Routes>
        {/* <Route path='/coba' element={<HomeCoba />} /> */}
        <Route element={<LayoutShiftScheduling />}>
          <Route element={<Dashboard />} >
            <Route index path='/' element={<EmployeeView />} />
            <Route path='/roleView' element={<RoleView />} />
          </Route>
        </Route>



      </Routes>
      
    </Router>
  );
}

export default AppRouter;