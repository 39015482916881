import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import employeeViewReducer from "./employeeViewSlice";
import loginSlice from "./userLoginSlice";
import requestReplacementSlice from "./requestReplacementSlice";

const persistConfig = {
	key: "root",
	storage,
};

const rootReducer = combineReducers({
	employeeView: employeeViewReducer,
	loginSlice,
	requestReplacement: requestReplacementSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: false
		});
	},
});

export const persistor = persistStore(store);
