import { useQuery, useMutation } from '@tanstack/react-query'
import customAxios from 'utils/axios'

const API = process.env.REACT_APP_API_SHIFT
const API_REPLACEMENT = `${API}/api/replacementrequest`;

export const useGetAllConflickData = ({ fromDate, toDate }) => {
    const allConflick = async () => {
        const res = await customAxios(`${API}/conflictdata?fromDate=${fromDate}&toDate=${toDate}`)
        return res.data;
    }

    return useQuery({
        queryKey: ["conflickAll"],
        queryFn: allConflick,
        onError: (err) => {
            console.log("Something wrong !", err)
        }
    })

}

// SHIFT REPLACEMENT HISTORY
export const useGetHistoryReplacement = () => {
    const historyApi = async () => {
        const res = await customAxios.get(`${API_REPLACEMENT}/gethistory`)
        return res.data;
    }

    return useQuery({
        queryFn: historyApi,
        queryKey: ["getHistoryReplacement"],
        onError: (err) => {
            console.log("Something wrong ", err)
        }
    })
}