import React from "react";
import { useGetHistoryReplacement } from "services/conflickDataApi";
import ApproveData from "components/approveData";

function ShiftReplacementAll() {
  const { data: historyReplacement, isLoading } = useGetHistoryReplacement();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {historyReplacement.data.length > 0 &&
        historyReplacement.data.map((item, index) => {
          const previousUserData = item.previousUserData;
          const replacementUserData = item.replacementUserData;
          const shiftData = item.shiftData;
          const assignShiftData = item.assignShiftData;
          const status = item.status;

          return (
            <ApproveData
              dateRequest={item.createdAt}
              assignShiftDate={assignShiftData?.date}
              shiftName={shiftData?.shiftName}
              locationName={previousUserData.locationName}
              jobPosition={assignShiftData?.jobPosition}
              startTime={shiftData?.startTime}
              endTime={shiftData?.endTime}
              employeeName={previousUserData.firstName}
              requestorPhoto={previousUserData.photo}
              replacementPhoto={replacementUserData?.photo}
              nameReplacement={replacementUserData.firstName}
              statusAproveReplacement={status.byEmployee.status}
            />
          );
        })}
    </div>
  );
}

export default ShiftReplacementAll;
