import React, { useState } from "react";
import {
  DataTable,
  InputSelect,
  SearchBox,
  CheckBox,
} from "@bluesilodev/timhutcomponents";

import { useGetAllEmployee } from "services/employeeApi";

function SelectedEmployee({
  setSelectedRows,
  selectedRows,
  selectAll,
  setSelectAll,
}) {
  const handleSelectAllChange = () => {
    if (selectAll) {
      // If already selected all, deselect all
      setSelectedRows([]);
    } else {
      // Select all employees
      setSelectedRows(allEmployee.map((emp) => emp.uId));
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelectChange = (empId) => {
    // console.log("INCLUDES ID : ", selectedRows.includes(empId));

    if (selectedRows.includes(empId)) {
      // Deselect the employee
      setSelectedRows(selectedRows.filter((id) => id !== empId));
    } else {
      // Select the employee
      setSelectedRows([...selectedRows, empId]);
    }
  };

  const { data: dataEmployee, isLoading: loadingEmployee } =
    useGetAllEmployee();

  if (loadingEmployee) {
    return <div>Loading...</div>;
  }

  const allEmployee =
    dataEmployee.data.length > 0 &&
    dataEmployee.data.flatMap((item) => {
      // FILTER EMPLOYEE NON ADMIN
      const nonAdmin = item.employee.filter((filter) => {
        return filter.role !== "Admin";
      });

      return nonAdmin.map((emp) => ({
        ...emp,
        jobPosition: item.jobPosition,
      }));
    });

  // const dataRow = [
  //   {
  //     select: false,
  //     employee: "jonny",
  //     location: "cafe halim",
  //     department: "operation",
  //     jobPosition: "supervisor",
  //   },
  // ];

  const dataColumn = [
    {
      id: "select",
      accessorFn: (row) => row,
      header: () => (
        <div>
          <CheckBox
            nameInput={"selectAll"}
            value={selectAll}
            onChange={handleSelectAllChange}
            borderColor={"white"}
          />
        </div>
      ),
      cell: (param) => {
        // console.log("value : ", param.getValue());
        const employee = param.getValue();
        const isSelected = selectedRows.includes(employee.uId);

        return (
          <div className="cursor-pointer w-fit">
            <CheckBox
              nameInput={`selectRow_${employee.uId}`}
              value={isSelected}
              onChange={() => handleRowSelectChange(employee.uId)}
              borderColor={"gray-400"}
            />
          </div>
        );
      },
      disableSorting: true,
    },

    {
      id: "employee",
      accessorFn: (row) => {
        return row.firstName;
      },
      header: () => <span>Employee</span>,
      enableSorting: true,
    },

    {
      id: "location",
      accessorFn: (row) => {
        const data = row?.locationName;
        return data;
      },
      header: () => <span>Location</span>,
      enableSorting: true,
    },
    {
      id: "department",
      accessorFn: (row) => {
        return row?.department;
      },
      header: () => <span>Department</span>,
      enableSorting: true,
    },
    {
      id: "jobPosition",
      accessorFn: (row) => row?.jobPosition,
      header: () => <span>Job Position</span>,
      enableSorting: true,
    },
  ];

  return (
    <div>
      <div className="flex gap-3">
        <InputSelect options={[]} title={"Locations"} />
        <InputSelect options={[]} title={"Department"} />
        <InputSelect options={[]} title={"JobPositions"} />
        <SearchBox />
      </div>

      <div className="mt-5">
        <DataTable columns={dataColumn} data={allEmployee} pagination={true} />
      </div>
    </div>
  );
}

export default SelectedEmployee;
