import React, { useState } from "react";
import {
  ModalDialog,
  ModalConfirmation,
  ShiftListBox,
} from "@bluesilodev/timhutcomponents";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { setLocationState } from "store/employeeViewSlice";

import FormShift from "shared/formShift/formShift";
// import ShiftListBox from "coba/shiftLists/ShiftListBox";
import { FiTrashNoBold, FiSaveSvg } from "assets/icons";

function RoleView() {
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalEditShift, setModalEditShift] = useState(false);
  const [shiftDetailId, setShiftDetailID] = useState();
  const [modalDelete, setShowModalDelete] = useState(false);

  const [location, setLocation] = useState();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // SET DATA SHIFT
  const [shiftDetailData, setShiftDetailData] = useState();

  const { dataDate } = useSelector((state) => state.employeeView);

  // HANDLE SHOW SHIFT DETAILS
  const handleShowShiftDetails = (data, idx) => {
    // console.log("DATA SHIFT DETAIL : ", data);
    setShiftDetailID(idx);
    setShiftDetailData(data);

    setModalEditShift(true);
  };

  // HANDLE SHOW ASSING SHIFT
  const handleShowAssign = (data, idx) => {
    console.log("uId : ", idx);

    console.log("DATA SHIFT : ", data);

    setShowModalAssign(true);
  };

  // SET LOCATION
  dispatch(setLocationState(location));

  const initialValue = {
    shiftName: "",
    color: "red",
    startTime: "",
    endTime: "",

    startBreakTime: "",
    endBreakTime: "",

    // REPEAT JUST ACCEPT VALUE ARRAY SEND TO API OBJECT > FROM - TO
    repeatDate: [],
    repeatBy: {
      repeatType: "",
      repeatValue: [],
    },
    locationId: "",

    employees: [
      {
        employeesNeeded: 0,
        jobPosition: "",
      },
    ],
  };

  return (
    <div>
      <div className="w-full">
        <ShiftListBox
          dataDate={dataDate}
          shiftDataTemplate={[]}
          handleClickFormShift={handleShowShiftDetails}
          handleShowAssign={handleShowAssign}
          dataEmployees={[]}
          // setLocation={setSelectLocation}
          dataAssignShift={[]}
          locationData={[]}
          // handleEditAssignShift={}
        />
      </div>

      {/* MODAL ASSIGN */}
      {showModalAssign && (
        <ModalDialog
          onClose={() => setShowModalAssign(false)}
          title={"Assign Shift"}
          className={"h-[700px] w-[750px] "}
        >
          {/* <FormAssignShift setShowModalAssign={setShowModalAssign} /> */}
        </ModalDialog>
      )}

      {/* MODAL EDIT SHIFT DERAIL */}
      {showModalEditShift && (
        <ModalDialog
          icon={[
            {
              icon: (
                <div className="flex gap-3 items-center mr-2">
                  <FiTrashNoBold
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  />
                </div>
              ),
            },
          ]}
          onClose={() => setModalEditShift(false)}
          title={"Shift Details"}
          className={"h-[700px] w-[750px] "}
        >
          <FormShift
            initialValue={initialValue}
            handleSubmit={(val) => {
              const allVal = {
                ...val,
                repeatDate: {
                  from: val.repeatDate[0],
                  to: val.repeatDate[val.repeatDate.length - 1],
                },
              };

              // updateShiftDetail(allVal);
            }}
            nameButton={"Update Shift"}
            iconButton={<FiSaveSvg />}
          />
        </ModalDialog>
      )}

      {/* MODAL DELETE SHIFT DETAIL */}
      {modalDelete && (
        <ModalConfirmation
          onCancel={() => {
            setShowModalDelete(false);
          }}
          // onConfirm={onConfirmDeleteShift}
        />
      )}
    </div>
  );
}

export default RoleView;
