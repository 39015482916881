import { useQuery } from '@tanstack/react-query'
import customAxios from 'utils/axios';

const API = `${process.env.REACT_APP_API_SHIFT}/api/common`;

export const useGetAppsQuery = () => {
  const getApps = async () => {
      const resp = await customAxios.get(`${API}/app`);
      return resp.data;
  }

  return useQuery({
      queryKey: ["apps"],
      queryFn: getApps,
      onError: (err) => {
        console.log("Something wrong", err)
      }
  });
}